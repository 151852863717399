/* eslint-disable react/no-array-index-key */
import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthRoute from 'src/components/AuthRoute';
import GuestRoute from 'src/components/GuestRoute';

import ConfirmEmail from 'src/views/auth/ConfirmEmail';
import CustomerListView from 'src/views/customers/CustomerListView';
import CustomerCreateView from 'src/views/customers/CustomerCreateView';

function Routes() {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        <Route path="/404" component={lazy(() => import('src/views/pages/Error404View'))} />
        <Route path="/pay/:companyId/:paymentId" component={lazy(() => import('src/views/payments/Stripe'))} />
        <Route path="/login" component={lazy(() => import('src/views/auth/Login'))} />
        <Route path="/reset-password" component={lazy(() => import('src/views/auth/PasswordRestart'))} />
        <AuthRoute path="/subscription" component={lazy(() => import('src/views/pricing/PricingView'))} />
        <GuestRoute path="/create-account" component={lazy(() => import('src/views/auth/Register'))} />
        <GuestRoute path="/thank-you" component={lazy(() => import('src/views/auth/ThankYou'))} />
        <Route path="/confirm/:id/:token" component={ConfirmEmail} />
        <Route path="/confirm-user/:id/:token" component={lazy(() => import('src/views/auth/ConfirmUser'))} />
        <Route
          path="/confirm-password-reset/:id/:token"
          component={lazy(() => import('src/views/auth/ConfirmPasswordReset'))}
        />
        <AuthRoute path="/account-settings" component={lazy(() => import('src/views/auth/AccountSettings'))} />
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Switch>
              <AuthRoute
                path="/dashboard"
                perform="menu:dashboard"
                component={lazy(() => import('src/views/reports/Dashboard'))}
              />

              <AuthRoute
                path="/company"
                perform="company:settings"
                component={lazy(() => import('src/views/auth/CompanyView'))}
              />
              <AuthRoute path="/account" component={lazy(() => import('src/views/auth/AccountView'))} />
              <AuthRoute exact path="/customers" perform="customers:list" component={CustomerListView} />
              <AuthRoute
                path="/customers/import"
                perform="customers:import"
                component={lazy(() => import('src/views/customers/CustomerImportView'))}
              />
              <AuthRoute path="/customers/create" perform="customers:create" component={CustomerCreateView} />
              <AuthRoute
                path="/customers/:customerId"
                perform="customers:view"
                component={lazy(() => import('src/views/customers/CustomerDetailsView'))}
              />
              <AuthRoute
                exact
                path="/groups"
                perform="groups:list"
                component={lazy(() => import('src/views/groups/GroupListView'))}
              />
              <AuthRoute
                exact
                path="/camps"
                perform="camps:list"
                component={lazy(() => import('src/views/camps/CampListView'))}
              />
              <AuthRoute
                exact
                path="/camps/create"
                perform="camps:create"
                component={lazy(() => import('src/views/camps/CampCreateView'))}
              />
              <AuthRoute
                exact
                path="/camps/payments/import"
                perform="camps:list"
                component={lazy(() => import('src/views/camps/CampImportPaymentsView'))}
              />
              <AuthRoute
                exact
                path="/camps/:campId"
                perform="camps:view"
                component={lazy(() => import('src/views/camps/CampDetailsView'))}
              />
              <AuthRoute
                exact
                path="/camps/registrations/:registrationId"
                perform="camps:view"
                component={lazy(() => import('src/views/camps/CampRegistrationView'))}
              />
              <AuthRoute
                path="/groups/import"
                perform="groups:import"
                component={lazy(() => import('src/views/groups/GroupImportView'))}
              />
              <AuthRoute
                path="/groups/create"
                perform="groups:create"
                component={lazy(() => import('src/views/groups/GroupCreateView'))}
              />
              <AuthRoute
                path="/groups/:groupId"
                perform="groups:list"
                component={lazy(() => import('src/views/groups/GroupDetailsView'))}
              />

              <AuthRoute
                exact
                path="/payments"
                perform="payments:list"
                component={lazy(() => import('src/views/payments/PaymentListView'))}
              />
              <AuthRoute
                exact
                path="/payments/import"
                perform="payments:import"
                component={lazy(() => import('src/views/payments/PaymentImportView'))}
              />
              <AuthRoute
                exact
                path="/registrations"
                perform="registrations:list"
                component={lazy(() => import('src/views/registrations/RegisterListView'))}
              />
              <AuthRoute
                exact
                path="/registrations/import"
                perform="registrations:list"
                component={lazy(() => import('src/views/registrations/RegisterImportView'))}
              />

              <Redirect from="/" to="/dashboard" />
              <Redirect from="/app" to="/dashboard" />
              <AuthRoute />
            </Switch>
          </Suspense>
        </DashboardLayout>
        {/* 
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <AuthRoute exact path="/customers" component={lazy(() => import('src/views/customers/CustomerListView'))} />
            <AuthRoute path="/dashboard" component={lazy(() => import('src/views/reports/Dashboard'))} />
          </Suspense>
        </DashboardLayout>
        */}
      </Switch>
    </Suspense>
  );
}

export default Routes;
